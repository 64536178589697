// import { isProduction } from '../../const/channel';
import {
  LOGIN_SCREEN_ROUTE,
  PASSWORD_CHANGE_SCREEN_ROUTE,
  PASSWORD_RESET_SCREEN_ROUTE,
} from '../../const/route';
// import { extra } from '../extra';

// const HOME_ENDPOINT = isProduction() ? extra?.productionHomeUrl : extra?.stagingHomeUrl;
const HOME_ENDPOINT = 'https://businesses-staging.premium-control.jp/';

const SHOP_LOGIN_FORM_SCRIPT = (value: string) => {
  return `
// フォーム全体の調整
const formStyleEl_${value} = document.querySelector('.container, .container-fluid');
formStyleEl_${value}.style.width = "100%";
formStyleEl_${value}.children[0].classList.remove('m-0');
formStyleEl_${value}.children[0].style.margin = "0 8px";

// フォームに影を追加
const formShadowEl_${value} = document.getElementsByClassName("card p-4")[0];
formShadowEl_${value}.style.boxShadow = "rgba(0, 0, 0, 0.2) 2px 2px 8px";
formShadowEl_${value}.style.borderRadius = "8px";

// ログイン画面 背景の調整
const formBgEl_${value} = document.querySelectorAll(".app")[0];
formBgEl_${value}.style.background = "linear-gradient(180deg, #4FC3CF 0%, #44A8B3 100%)";

// ログインボタンの調整
const submitButtonEl_${value} = document.querySelectorAll("button[type='submit']")[0];
submitButtonEl_${value}.style.background = "#1C8F9C";
submitButtonEl_${value}.style.fontSize = "16px";
`;
};

// バックボタンを追加
const BACK_BUTTON_SCRIPT = (text: string, link: string, value: string) => {
  return `
const linkEl_${value} = document.createElement("a");
const linkText_${value} = document.createElement("span");
linkText_${value}.textContent = "＜ ${text}";
linkText_${value}.style.color = "#FFFFFF";
linkText_${value}.style.fontSize = "13px";
linkText_${value}.style.lineHeight = "16px";
linkText_${value}.style.margin = "0 8px 8px 8px";
linkText_${value}.classList.add("col-md-6", "p-0");
linkEl_${value}.setAttribute("href", "${link}");
linkEl_${value}.classList.add("back-link", "row", "justify-content-center", "m-0");;
linkEl_${value}.prepend(linkText_${value});
document.querySelector('.container, .container-fluid').prepend(linkEl_${value});
`;
};

// ボタンの追加
const SHOW_CAMERA_BUTTON_SCRIPT = () => {
  const text = 'QR読取カメラを起動';
  const cameraIconSVG = `<svg fill="#ffffff" height="50px" width="50px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 487 487" xml:space="preserve" stroke="#ffffff"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <g> <g> <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9 v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"></path> </g> </g> </g></svg>`;
  return `
const cardBodyEl = document.querySelector('.card-body');
cardBodyEl.style.position = "relative";
const cameraButtonEl = document.createElement("button");
cameraButtonEl.innerHTML = "${text}";
const iconContainer = document.createElement('div');
iconContainer.innerHTML = '${cameraIconSVG}';
cameraButtonEl.style.color = "#ffffff";
cameraButtonEl.prepend(iconContainer);
cameraButtonEl.style.backgroundColor = "#20a8d8";
cameraButtonEl.style.border = "none";
cameraButtonEl.style.borderRadius = "12px";
cameraButtonEl.style.padding = "12px";
cameraButtonEl.style.position = "absolute";
cameraButtonEl.style.top = "50%";
cameraButtonEl.style.left = "50%";
cameraButtonEl.style.transform = "translate(-50%, -50%)";
cameraButtonEl.addEventListener('click', event => {
  event.preventDefault();
  if ('ReactNativeWebView' in window) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ 
        event: 'open-qr-code-reader',
      }),
    )
  };
});
cardBodyEl.prepend(cameraButtonEl);
`;
};

// パスワード保存ダイアログの後にformをsubmitする
const SUBMIT_CODE = `
setTimeout(() => {
  document.querySelector('form').submit(); 
}, 100);
`;

// パスワード変更フォーム送信
const PASSWORD_CHANGE_SUBMIT_CODE = `
setTimeout(() => {
  document.querySelectorAll('form')[1].submit();
}, 100);
`;

// 共通の初期スクリプト
const COMMON_INJECT_SCRIPT = `true;`;

const ANDROID_INJECT_SCRIPT = COMMON_INJECT_SCRIPT;

const IOS_WINDOW_SIZE_SCRIPT = `
const meta = document.createElement('meta');
meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);
`;

const IOS_INJECT_SCRIPT = `
${IOS_WINDOW_SIZE_SCRIPT}
${COMMON_INJECT_SCRIPT}
`;

// 自治体ログイン画面
const SHOP_LOGIN_SCREEN_SCRIPT = `
if(window.location.pathname === '${LOGIN_SCREEN_ROUTE}') {
  ${SHOP_LOGIN_FORM_SCRIPT('login')}
  ${BACK_BUTTON_SCRIPT('地域選択に戻る', HOME_ENDPOINT, 'login')}
  
  // パスワード保存
  const formEl = document.querySelector('form');
  formEl.addEventListener('submit', event => {
    event.preventDefault();
    // アプリ側にID/PASSを送信
    const emailValue = document.querySelector('[name="email"]').value;
    const passwordValue = document.querySelector('[name="password"]').value;
    if ('ReactNativeWebView' in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ 
          event: 'login',
          data: {
            email: emailValue,
            password: passwordValue
          }
        }),
      )
    };
  });
}
true;
`;

const PASSWORD_RESET_SCREEN_SCRIPT = `
if(window.location.pathname === '${PASSWORD_RESET_SCREEN_ROUTE}') {
  ${SHOP_LOGIN_FORM_SCRIPT('reset')}
  ${BACK_BUTTON_SCRIPT('ホームに戻る', LOGIN_SCREEN_ROUTE, 'reset')}
  
  // アラート文の変更
  const alertEl = document.getElementsByClassName("alert alert-success");
  if(alertEl.length > 0) alertEl[0].textContent = "パスワードを再設定後このアプリでログインください。";
};
true;
`;

const PASSWORD_CHANGE_SCREEN_SCRIPT = `
if(window.location.pathname === '${PASSWORD_CHANGE_SCREEN_ROUTE}') {
  // パスワード保存 0番目はログアウトform
  const changeFormEl = document.querySelectorAll('form')[1];
  changeFormEl.addEventListener('submit', event => {
    event.preventDefault();
    // アプリ側にID/PASSを送信
    const passwordValue = document.querySelector('[name="password"]').value;
    if ('ReactNativeWebView' in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ 
          event: 'password-change',
          data: {
            password: passwordValue
          }
        }),
      )
    };
  });
}
true;
`;

const QRCODE_READ_SCREEN_SCRIPT = `
  // TODO: この方法で問題ないか、停止の方法を別途検討する
  // WebViewカメラを停止する
  const stopButtonEl = document.getElementById('stop-btn');
  if(stopButtonEl) stopButtonEl.click();

  // カメラ、ボタンの非表示処理
  const cameraEL = document.querySelectorAll('.card-body .row');
  if(cameraEL) cameraEL[0].remove();
  if(cameraEL) cameraEL[1].remove();

  // ボタンを中心にもってくるため、WebView側のスタイルを変更
  document.querySelector('.container-fluid').style.height = '70%';
  document.querySelector('.container-fluid .animated').style.height = '100%';
  document.querySelector('.container-fluid .animated .row').style.height = '100%';
  document.querySelector('.card').style.height = '100%';
`;

export {
  HOME_ENDPOINT,
  ANDROID_INJECT_SCRIPT,
  IOS_INJECT_SCRIPT,
  SUBMIT_CODE,
  PASSWORD_CHANGE_SUBMIT_CODE,
  SHOP_LOGIN_SCREEN_SCRIPT,
  PASSWORD_RESET_SCREEN_SCRIPT,
  PASSWORD_CHANGE_SCREEN_SCRIPT,
  QRCODE_READ_SCREEN_SCRIPT,
  SHOW_CAMERA_BUTTON_SCRIPT,
};
