import * as Device from 'expo-device';
import * as ScreenOrientation from 'expo-screen-orientation';
import React, { useEffect } from 'react';
import * as Sentry from 'sentry-expo';

import NativeApp from './src/app';
// import { isProduction } from './src/const/channel';
import StateProvider from './src/utils/StateProvider';
// import { extra } from './src/utils/extra';
import Web from './src/web';

Sentry.init({
  // dsn: extra?.sentryDNS,
  dsn: 'https://962065b8aa4f462f82d24ce38a3aef4f@o4504882183143424.ingest.sentry.io/4505242901872640',
  enableInExpoDevelopment: true,
  debug: false,
  attachScreenshot: true,
  // environment: isProduction() ? 'production' : 'staging',
  environment: 'staging',
});

export default function App() {
  useEffect(() => {
    if (Device.deviceType !== Device.DeviceType.TABLET) {
      ScreenOrientation.lockAsync(2).catch(() => {});
    }

    return () => {
      ScreenOrientation.unlockAsync();
    };
  }, []);

  return <StateProvider>{Device.osBuildId === null ? <Web /> : <NativeApp />}</StateProvider>;
}
